$main-color-admin: #131452;
$bg-color-admin: #e5dcd1;
$primary-font-color: $black-color-v2;
$th-bg-fa: #faee00;
$th-bg-93: #93d2ef;
$th-bg-72: #727171;
$bg-brown-97: #978475;
$bg-border-table: #595757;
$bg-nav-b5: #e4e4e4;
$border-color-nav: #b5b5b6;
$white-color: #fff;
$red-color: #ff0000;
