.change-service-block {
  padding: 0 30px;
  .title {
    @include flexbox(flex, flex-start, center, nowrap);
    gap: 6px;
    padding: 22px 0 14px 10px;
    border-bottom: 2px solid $button-color-first;
    img {
      width: 13px;
      height: 13px;
    }
    h1 {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .list-services {
    .ant-collapse {
      background-color: $white-color;
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0;
          .header-service-item {
            padding: 16px 12px 16px 10px;
            @include flexbox(flex, space-between, center, nowrap);
            .detail {
              font-size: 14px;
              font-weight: 500;
            }
            .icon-plus-minus {
              width: 11px;
              height: 11px;
            }
          }
        }
        .ant-collapse-content {
          background-color: rgba(220, 221, 221, 0.16);
          .ant-collapse-content-box {
            padding: 0 0 12px 0;
            .service-detail {
              .service-detail-element {
                border-bottom: 1px solid $border-bottom-default;
                padding: 15px 18px 12px 16px;
                @include flexbox(flex, space-between, center, nowrap);
                .field {
                  margin-right: 20px;
                  font-size: 12px;
                  font-weight: 500;
                }
                .value {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .btn-extend {
                margin-top: 10px;
                padding-right: 18px;
                @include flexbox(flex, flex-end, center, nowrap);
                gap: 15px;
                .ant-btn {
                  border-radius: 4px;
                  height: 34px;
                  max-width: 76px;
                  width: 100%;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 0;
                }
                .change {
                  background-color: $button-disable;
                }
                .cancel {
                  background-color: #e83828;
                }
              }
            }
          }
        }
      }
      .ant-collapse-item:last-child {
        border-bottom: 1px solid $border-calendar;
      }
      .collapse-open {
        border-bottom: 2px solid $button-color-first;
      }
    }
    .note {
      display: block;
      padding: 11px 12px 0px 10px;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .instruct {
    display: block;
    margin-top: 18px;
    margin-bottom: 26px;
    font-size: 12px;
    font-weight: 500;
    a {
      text-decoration: underline;
      color: #036eb7;
    }
  }
  .block-btn {
    margin-top: 30px;
    margin-bottom: 60px;
    .ant-btn {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-btn:first-child {
      color: $white-color;
      background-color: $button-disable;
    }
  }
  @include mq_min_576 {
    padding: 0 60px;
    .title {
      padding: 43px 0 28px 20px;
      border-bottom: 2px solid $button-color-first;
      h1 {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: -2px;
      }
    }
    .list-services {
      .ant-collapse {
        background-color: $white-color;
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 0;
            .header-service-item {
              padding: 33px 23px 31px 20px;
              @include flexbox(flex, space-between, center, nowrap);
              .detail {
                font-size: 24px;
                font-weight: 500;
                margin-right: 20px;
              }
              .icon-plus-minus {
                width: 21px;
                height: 21px;
              }
            }
          }
          .ant-collapse-content {
            .ant-collapse-content-box {
              padding: 0 0 25px 0;
              .service-detail {
                .service-detail-element {
                  border-bottom: 1px solid $border-bottom-default;
                  padding: 29px 35px 23px 32px;
                  @include flexbox(flex, space-between, center, nowrap);
                  .field {
                    font-size: 20px;
                    font-weight: 500;
                  }
                  .value {
                    font-size: 24px;
                    font-weight: 500;
                  }
                }
                .btn-extend {
                  margin-top: 21px;
                  padding-right: 35px;
                  @include flexbox(flex, flex-end, center, nowrap);
                  gap: 30px;
                  .ant-btn {
                    border-radius: 4px;
                    height: 68px;
                    max-width: 152px;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 500;
                  }
                  .change {
                    background-color: $button-disable;
                  }
                  .cancel {
                    background-color: #e83828;
                  }
                }
              }
            }
          }
        }
        .ant-collapse-item:last-child {
          border-bottom: 1px solid $border-calendar;
        }
        .collapse-open {
          border-bottom: 2px solid $button-color-first;
        }
      }
      .note {
        display: block;
        padding: 22px 23px 0px 20px;
        font-size: 20px;
        font-weight: 300;
      }
    }
    .instruct {
      display: block;
      margin-top: 36px;
      margin-bottom: 52px;
      font-size: 20px;
      font-weight: 500;
      a {
        text-decoration: underline;
        color: #036eb7;
      }
    }
    .block-btn {
      margin-top: 61px;
      margin-bottom: 120px;
      .ant-btn {
        font-size: 24px;
        font-weight: 500;
      }
      .ant-btn:first-child {
        color: $white-color;
        background-color: $button-disable;
      }
    }
  }
}

.ant-btn:hover {
  opacity: 0.7;
}
