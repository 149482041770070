.coupon-code-wrapper {
  height: calc(100vh - 192px - 200px);
  display: flex;
  align-items: center;
  justify-content: center;

  .coupon-code-content {
    flex-direction: column;
    @include flexbox(flex, center, center, nowrap);

    .header-coupon {
      margin-top: 71px;
      @include font(16px, 400, 28px);
    }

    .coupon-item {
      @include font(20px, 400, 35px);
    }

    .btn-coupon {
      height: 36px;
      width: 183px;
      text-align: center;
      margin-top: 21px;
      border: 2px solid $border-bottom-v2;
      border-radius: 4px;

      &::placeholder {
        color: #4d4d4d;
        border: 2px solid $border-bottom-v2;
        @include font(12px, 600, 21px);
      }
    }

    .ant-space-item {
      margin: 0 auto;

      .ant-btn-default:not(:disabled):hover {
        color: $black-color;
        border-color: 2px solid $border-bottom-v2;
      }
    }
  }

  .btn-accept-coupon {
    width: 100%;
    @include flexbox(flex, center, null, null);

    .btn-coupon {
      height: 36px;
      width: 183px;
      text-align: center;
      margin-top: 21px;
      border: 2px solid $border-bottom-v2;
      border-radius: 4px;
      background-color: $white-color;

      &::placeholder {
        color: #4d4d4d;
        border: 2px solid $border-bottom-v2;
        @include font(12px, 600, 21px);
      }

      &:hover {
        cursor: pointer;
        color: $black-color;
        opacity: 0.7;
      }
    }
  }

  .ant-upload {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-btn-default:not(:disabled):hover {
    color: rgba(0, 0, 0, 0.88);
  }
}
