.header-login {
  @include flexbox(flex, center, center, nowrap);
  margin-bottom: convertToVw(50px);
  margin-top: convertToVw(70px);

  @include mq_min_750 {
    margin-bottom: 50px;
    margin-top: 70px;
  }

  img {
    width: convertToVw(181px);
    height: convertToVw(283px);

    @include mq_min_750 {
      width: 260px;
      height: 391.5px;
    }
  }
}

.note-login {
  max-width: convertToVw(640px);
  text-align: center;
  min-height: convertToVw(131px);
  background-color: #f0e9dd;
  margin-bottom: convertToVw(40px);
  border-radius: convertToVw(10px);
  display: none;

  h2 {
    @include font(convertToVw(18px), 600, convertToVw(31px));
    padding-top: convertToVw(16px);
  }
  p {
    @include font(convertToVw(16px), 600, convertToVw(27px));
    padding-bottom: convertToVw(15px);
    margin-top: convertToVw(10px);
  }

  @include mq_min_750 {
    min-height: 131px;
    max-width: 640px;
    margin-bottom: 40px;
    border-radius: 10px;
    display: inline-block;

    h2 {
      @include font((18px), 600, (31px));
      padding-top: 16px;
    }
    p {
      @include font((16px), 600, (27px));
      padding-bottom: 15px;
      margin-top: 10px;
    }
  }
}

.form-login {
  @include flexbox(flex, center, center, null);
  width: convertToVw(610px);
  @include mq_min_750 {
    width: 610px;
  }

  .ant-form {
    width: 100%;
  }
  form {
    .input-global {
      @include font(convertToVw(16px), 500, convertToVw(27px));
      height: convertToVw(60px) !important;

      @include mq_min_750 {
        @include font(16px, 500, 27px);
        height: 60px !important;
      }
    }

    .form-group:nth-child(1) {
      margin: 0 0 convertToVw(25px);

      @include mq_min_750 {
        margin: 0 0 25px;
      }

      input {
        background-color: $white-color !important;

        padding: convertToVw(22px) convertToVw(16px) convertToVw(22px) convertToVw(28px);
        margin: 0px;
        @include font(convertToVw(23px), 500, convertToVw(40px));
        height: convertToVw(81px) !important;

        &::placeholder {
          color: $placeholder-color !important;
          font-weight: normal;
        }

        @include mq_min_750 {
          padding: 22px 16px 22px 28px;
          @include font(23px, 500, 40px);
          height: 81px !important;
        }
      }

      .ant-col {
        #email_help {
          display: none;
        }
      }
    }
    .ant-input-affix-wrapper:focus {
      box-shadow: none !important;
      border-inline-end-width: 2px;
      background-color: transparent;
    }
    .form-group:nth-child(2) {
      input {
        margin-bottom: convertToVw(13px);

        @include mq_min_750 {
          margin-bottom: 13px;
        }
      }
    }

    .form-group-bottom {
      margin-bottom: convertToVw(16px);

      @include mq_min_750 {
        margin-bottom: 16px;
      }

      .ant-input-password {
        height: convertToVw(81px) !important;
        padding: convertToVw(20px) convertToVw(16px) convertToVw(20px) convertToVw(28px);
        margin-bottom: 0;
        background-color: $white-color !important;
        @include font(convertToVw(23px), 500, convertToVw(40px));

        @include mq_min_750 {
          height: 81px !important;
          padding: 20px 16px 20px 28px;
          @include font(23px, 500, 40px);
        }

        .ant-input {
          &::placeholder {
            color: $placeholder-color !important;
            font-weight: normal;
          }
        }
      }

      .ant-col {
        #password_help {
          display: none;
        }
      }

      span {
        background-color: transparent;

        svg {
          font-size: convertToVw(22px);

          @include mq_min_750 {
            font-size: 22px;
          }
        }
      }
    }

    .block-error {
      margin: convertToVw(40px) 0 convertToVw(58px);

      @include mq_min_750 {
        margin: 40px 0 58px;
      }

      .error-validate {
        color: #e50012;

        height: auto;

        margin: 0;

        @include font(convertToVw(14px), 500, convertToVw(28px));

        @include mq_min_750 {
          @include font(14px, 500, 28px);
        }

        .text-danger {
          white-space: nowrap;
          @include font(convertToVw(20px), 500, convertToVw(35px));
          @include mq_min_750 {
            @include font(20px, 500, 35px);
          }
        }
      }
    }

    .form-group-checkbox-login {
      color: $white-color;
      @include flexbox(flex, space-between, center, nowrap);

      .checkbox-login-element {
        @include flexbox(flex, center, center, null);

        input {
          margin: 0 convertToVw(9px) 0 0;
          width: convertToVw(20px);
          height: convertToVw(20px);

          @include mq_max_576 {
            width: 15px;
            height: 15px;
          }

          @include mq_min_750 {
            margin: 0 9px 0 0;
            width: 20px;
            height: 20px;
          }
        }

        @supports (-webkit-touch-callout: none) {
          input {
            padding: 0;
          }
        }

        span {
          @include font(convertToVw(24px), 500, convertToVw(42px));

          @include mq_min_750 {
            @include font(24px, 500, 42px);
          }
        }

        .label-check-pwd {
          @include mq_min_750 {
            margin-top: 1px;
          }
        }
      }
    }

    .btn-login-container {
      margin: convertToVw(37px) 0 convertToVw(26px);

      @include mq_min_750 {
        margin: 37px 0 26px;
      }

      .btn-login {
        width: convertToVw(290px);
        height: convertToVw(77px);
        @include font(convertToVw(23px), 500, convertToVw(40.6px));

        @include mq_min_750 {
          @include font(23px, 500, 40.6px);
          width: 290px;
          height: 77px;
        }
      }
    }
  }

  .forgot {
    color: $white-color;
    flex-direction: column;

    @include flexbox(flex, center, flex-start, nowrap);

    span {
      height: auto;

      margin: 0 0 convertToVw(27px);

      font-size: convertToVw(24px);
      white-space: nowrap;
      line-height: convertToVw(30px);

      @include mq_min_750 {
        margin: 0 0 27px;

        font-size: 24px;
        line-height: 30px;
      }

      a {
        display: inline;
        color: $white-color;
        text-decoration: underline;
      }
    }

    span.forgot-password {
      margin-bottom: 0;
    }
  }
}

.forgot {
  span {
    margin-top: convertToVw(10px);
    @include font(convertToVw(24px), 500, convertToVw(30px));
    display: block;
    color: white;
    a {
      color: $white-color;
    }

    @include mq_min_750 {
      margin-top: 10px;
      @include font(24px, 500, 30px);
    }
  }
}

.have-account {
  text-align: center;
  color: $white-color;
  margin-top: convertToVw(55.5px);

  h1 {
    font-weight: 500;
    font-size: convertToVw(28px);
  }

  @include mq_min_750 {
    margin-top: 55.5px;
    h1 {
      font-size: 28px;
    }
  }
}

.btn-login-container-bottom {
  margin: convertToVw(25px) 0 convertToVw(48px) 0;

  @include mq_min_750 {
    margin: 25px 0 48px 0;
  }

  button {
    color: $black-color;
    font-weight: 500;
    @include font(convertToVw(23px), 500, convertToVw(40.6px));

    @include mq_min_750 {
      @include font(23px, 500, 40.6px);
    }
  }

  & .btn-register-member {
    background-color: #e6d4ae;
    border: none;
    border-radius: convertToVw(10px);
    height: convertToVw(97px);
    width: convertToVw(610px);

    @include mq_min_750 {
      border-radius: 10px;
      width: 610px;
      height: 97px;
    }
  }
}
