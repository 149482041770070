// Button bg color
$button-color-first: #693f23;
$button-disable: #9c9e9f;
$ct-allow: #693f23;
$cr-allow: #693f23;
$danger-color: #e40012;
$danger-color-v2: #ff4d4f;
$btn-dot: #e20012;
$border-color: #c5c6c6;
$bg-color-input: #e6e6e6;
$divided-color: #703d1c;
$border-bottom-default: #bfbfbf;
$border-bottom-v2: #707070;
$background-color: $bg-color-input;
$selected-color: rgba(#008aff, 0.86);
$white-color: #fff;
$black-color: #000000;
$black-color-v2: #1a1a1a;
$placeholder-color: #808080;
$bg-btn-add-hour: #2ea7e0;
$font-color-new: #666666;
$border-calendar: #d9d9d9;
$btn-delete-color: #ff0000;
$border-user-color: #b2b2b3;
$calendar-color-matching: red;
$btn-calendar-enable: #1087ff;
$color-border-input: #e50012;
$transparent-color: transparent;
$outline-btn-calendar: #c9caca;
$background-light-blue: rgba(#009ce5, 0.3);

//Spacing
$width-input: 630px;
$height-input: 100px;
$height-element-modal: 87px;
$height-element-modal-small: 44px;
$height-content-modal: 1044px;
$height-content-modal-small: 528px;
$mq_min_1024: '(min-width: 1024px)';
