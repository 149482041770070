.block-password-new {
  margin-top: convertToVw(80px);

  @include mq_min_750 {
    margin-top: 80px;
  }
  input::placeholder {
    color: $placeholder-color !important;
  }
  input::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  .head-title {
    .item-title {
      @include font(convertToVw(30px), 500, convertToVw(49px));

      @include mq_min_750 {
        font-size: 30px;
        line-height: 49px;
      }
    }
  }
  .block-change-input {
    position: relative;
    margin-bottom: 45px;
  }

  & .check-item {
    @include flexbox(flex, flex-end, center, null);
    @include font(convertToVw(20px), 300, convertToVw(35px));
    right: 0;
    position: absolute;
    top: convertToVw(110px);

    .checkbox-passwd-item {
      position: absolute;
      top: 120px;
    }

    & input {
      width: convertToVw(20px);
      height: convertToVw(20px);
      background-color: $bg-color-input;
    }

    & span {
      margin-left: convertToVw(7px);
    }

    @include mq_min_750 {
      @include font(20px, null, 35px);
      top: 110px;

      & input {
        width: 20px;
        height: 20px;
      }

      & span {
        margin-left: 7px;
      }
    }
  }
  & .rule-item {
    display: block;
    @include font(convertToVw(20px), 500, convertToVw(29px));
    margin-top: convertToVw(40px);
    margin-bottom: convertToVw(32.4px);

    @include mq_min_750 {
      @include font(20px, null, 29px);
      margin-top: 40px;
      margin-bottom: 32.4px;
    }
  }

  & span:nth-child(5) {
    margin-bottom: convertToVw(20px);

    @include mq_min_750 {
      margin-bottom: 20px;
    }
  }
  .term-change {
    max-width: convertToVw(630px);
    margin: convertToVw(47.5px) auto convertToVw(217.5px);
    text-align: center;
    @include font(convertToVw(26px), 300, convertToVw(46px));

    h2 {
      margin-bottom: convertToVw(60px);
      white-space: nowrap;
    }

    @include mq_min_750 {
      max-width: 630px;
      margin: 47.5px auto 217.5px;
      @include font(26px, null, 46px);

      h2 {
        margin-bottom: 60px;
      }
    }
  }

  .ant-form-item-explain-error {
    text-align: left;
    margin-top: 25px;
  }
}

.block-btn-new-password {
  button {
    margin: 0 auto;
  }
}
