.block-password-change {
  margin-top: convertToVw(80px);

  @include mq_min_750 {
    margin-top: 80px;
  }

  .ant-form-item .ant-form-item-explain-error {
    text-align: start;
    margin: convertToVw(11px) 0 convertToVw(21px) convertToVw(25px);

    @include mq_min_750 {
      margin: 11px 0 21px 25px;
    }
  }

  .content .menu-content .head-title .item-title {
    @include font(convertToVw(30px), 500, convertToVw(49px));

    @include mq_min_750 {
      @include font(30px, null, 49px);
    }
  }

  .title-change-password .item-title {
    padding-bottom: convertToVw(28px);
    border-bottom: 2px solid $button-color-first;
    @include font(convertToVw(30px), 400, convertToVw(50px));

    @include mq_min_750 {
      padding-bottom: 28px;
      @include font(30px, null, 50px);
    }
  }

  .item-des {
    white-space: nowrap;
    text-align: center;

    .text-term-change {
      margin-bottom: convertToVw(44px);
      text-align: center;

      @include mq_min_750 {
        margin-bottom: 14px;
      }
    }

    & p {
      letter-spacing: convertToVw(-1.5px);
      @include font(convertToVw(24px), 400, convertToVw(42px));

      @include mq_min_750 {
        letter-spacing: -1.5px;
        @include font(26px, null, 42px);
      }
    }
  }
  .term-change {
    padding: convertToVw(45px) 0 0 0;
    line-height: convertToVw(46px);

    .input-group-term {
      text-align: center;
    }

    @include mq_min_750 {
      padding: 45px 0 0 0;
      line-height: 46px;
    }
  }

  & .rule-item {
    display: block;
    @include font(convertToVw(20px), 500, convertToVw(29px));

    @include mq_min_750 {
      @include font(20px, null, 29px);
    }
  }

  .block-change-password {
    display: flex;
    column-gap: convertToVw(15px);
    margin-top: convertToVw(390px);

    @include mq_min_750 {
      column-gap: 15px;
      margin-top: 390px;
    }
  }
  .form-item {
    width: 100%;
    margin: 0 auto;
    margin-top: convertToVw(43px);
    padding: convertToVw(41px);
    border-radius: convertToVw(9px);
    border-style: none;
    max-height: convertToVw(100px);
    max-width: convertToVw(630px);
    background-color: $bg-color-input;
    font-size: convertToVw(24px);

    &::placeholder {
      color: $placeholder-color;
    }

    @include mq_min_750 {
      padding: 41px;
      max-width: 630px;
      margin-top: 43px;
      max-height: 100px;
      border-radius: 9px;
      font-size: 24px;
    }
  }
}
