// custom checkbox antd
.ant-checkbox .ant-checkbox-inner {
  background-color: $bg-color-input;
  position: relative;
  width: convertToVw(55px);
  height: convertToVw(55px);

  @include mq_min_750 {
    width: 55px;
    height: 55px;
  }

  &::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transform: scale(1) translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border: none;
    cursor: pointer;
    width: 3.2vw;
    height: 3.2vw;
    background: $black-color;

    @include mq_min_750 {
      width: 24px;
      height: 24px;
    }
  }
}

.ant-checkbox + span {
  @include font(convertToVw(24px), 300, convertToVw(42px));
  border: none;
}

.ant-radio-wrapper .ant-radio-inner {
  width: convertToVw(55px);
  height: convertToVw(55px);
  border-radius: 0;
  background-color: $bg-color-input;

  &::after {
    content: url(../../images/mockup/icon-dot-small.svg);
    transform: rotate(45deg) scale(1) translate(-20%, -40%);
    border: none;
  }
}

.ant-form label {
  display: flex;
  align-items: center;
  @include font(convertToVw(24px), 400, convertToVw(42px));
}

.ant-form label.warning {
  color: $btn-delete-color;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
  opacity: 0.8;
  border: 2px solid $color-border-input;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper {
  opacity: 0.8;
  border: 2px solid $color-border-input;
}

.error-validate {
  width: 100%;
  height: convertToVw(80px);

  display: flex;
  justify-content: flex-start;

  margin: convertToVw(35px) 0 convertToVw(30px) 0;

  border-radius: convertToVw(9px);

  letter-spacing: convertToVw(-3px);
  @include font(convertToVw(20px), 400, convertToVw(35px));

  @include mq_min_750 {
    height: 80px;

    margin: 35px 0 30px 0;

    border-radius: 9px;

    letter-spacing: -3px;
    @include font(20px, 400, 35px);
  }
}

//custom-modal
.ant-modal {
  padding: 0;
  width: convertToVw(680px) !important;
  top: convertToVw(52px);
  font-family: 'Hiragino Sans', sans-serif !important;

  .modal-date {
    height: convertToVw(900px);
    width: convertToVw(680px);
  }

  .ant-modal-content {
    padding: 0;
    border-radius: convertToVw(30px);

    & .ant-modal-close {
      width: convertToVw(60px);
      height: convertToVw(60px);
      margin-top: convertToVw(5px);

      svg {
        width: convertToVw(30px);
        height: convertToVw(30px);
      }
    }
  }
  & .ant-modal-close-x {
    font-size: convertToVw(30px);

    img {
      width: convertToVw(44px);
      height: convertToVw(36px);
    }
    svg {
      width: convertToVw(60px);
      height: convertToVw(60px);
      margin-right: convertToVw(30px);
      padding-top: convertToVw(10px);
    }
  }
  & .ant-modal-header {
    width: 100%;
    background-color: #ededed;
    margin-bottom: 0;
    border-radius: convertToVw(30px) convertToVw(30px) 0 0;

    & .ant-modal-title {
      padding: convertToVw(41px) convertToVw(40px) convertToVw(34px) convertToVw(41px);
      @include font(convertToVw(30px), 400, convertToVw(52.5px));

      .head-modal-title {
        @include font(convertToVw(30px), 400, convertToVw(36px));
      }
    }
  }
  & .ant-modal-body {
    overflow: overlay;
    max-height: 75vh;
    /* Handle */
    .item-hour-line .note-hour-register {
      border-bottom: 2px solid $button-color-first;
      padding: 0 !important;
      margin-top: convertToVw(31px);
      @include font(convertToVw(20px), 500, convertToVw(34px));
      padding-bottom: convertToVw(32px) !important;

      @include mq_min_750 {
        @include font(20px, 500, 34px);
        padding-bottom: 32px !important;
        margin-top: 31px;
      }
    }

    & p {
      border-bottom: 1px solid #b3b3b3;
      cursor: pointer;
      padding: convertToVw(32px) convertToVw(32px) convertToVw(28px) convertToVw(41px) !important;
      @include font(convertToVw(24px), 300, convertToVw(30px));

      &:nth-last-child(-n + 1) {
        border-bottom: none;
      }
    }

    &::-webkit-scrollbar {
      background-color: $transparent-color;
      width: convertToVw(10px);
      border-radius: convertToVw(20px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #595757;
      border-radius: convertToVw(20px);
    }
  }
}

.ant-modal.modal-delete-cast {
  width: convertToVw(480px) !important;

  img {
    width: convertToVw(70px);
  }

  .modal-delete-wrap {
    padding: convertToVw(24px) convertToVw(24px);
  }

  .text-delete {
    font-size: convertToVw(24px);
    display: inline-block;
  }

  .delete-actions {
    display: flex;
    gap: convertToVw(20px);
    margin-top: convertToVw(26px);
    justify-content: flex-end;
  }

  button {
    min-width: convertToVw(100px);
    height: convertToVw(40px);
    cursor: pointer;
    font-size: convertToVw(20px);
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  .btn-cancel {
    border: 1px solid #ccc;
    background-color: $transparent-color;
    order: 2;
  }

  .btn-delete {
    background-color: #5999fe;
    color: $white-color;
    border: none;
  }
}

//custom-modal emotion
.close-modal {
  width: convertToVw(40px);
  height: convertToVw(40px);
  svg {
    width: convertToVw(37px);
    height: convertToVw(35px);
  }
}

.ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper {
  padding: convertToVw(20px);
}

.modal-container-emotion {
  text-align: center;

  .icon-modal {
    display: flex;
    justify-content: center;
    margin-bottom: convertToVw(28px);
  }

  .btn-bottom-emotion {
    margin-top: convertToVw(30px);

    .ct-allow {
      margin-bottom: convertToVw(15px);
    }
  }
}

.ant-modal.ant-modal-content.ant-modal-body {
  & p:nth-last-child(-n + 1) {
    border-bottom: none !important;
  }
}

.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
  .ant-checkbox-inner {
    border: convertToVw(3px) solid $danger-color-v2 !important;
  }
}

.ant-form-item .ant-form-item-explain-error {
  color: #e30012;
  @include font(convertToVw(20px), 500, convertToVw(35px));
}
.ant-form-item {
  .ant-form-item-explain-error {
    color: $color-border-input;

    @include font(convertToVw(20px), 500, convertToVw(35px));

    @include mq_min_750 {
      @include font(20px, null, 35px);
    }
  }
}

.checkbox:checked + .checkboxLabel::before {
  background-color: firebrick;
  border-radius: convertToVw(2px);
  color: $white-color;
  content: '\2713';
  font-size: convertToVw(19px);
  line-height: convertToVw(18px);
}

.calendar .selected {
  background-color: $calendar-color-matching !important;
  color: $white-color;
}

//custom form
.ant-form-item {
  margin-bottom: 0;
}

.ant-input-affix-wrapper > input.ant-input {
  -webkit-background-clip: text !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: 0;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
  margin-bottom: convertToVw(35px);
}

.ant-select-selection-placeholder {
  display: flex;
}

// ant disable custom
.ant-btn-primary:not(:disabled):active {
  background-color: red !important;
}

//annotate calendar modal
.annotate {
  display: flex;
  align-items: center;
  gap: convertToVw(26px);
  margin-top: convertToVw(20px);
  @include font(convertToVw(20px), null, convertToVw(35px));

  &-item {
    display: flex;
    align-items: center;

    span {
      margin-left: convertToVw(7px);
    }
  }

  &-square {
    width: convertToVw(20px);
    height: convertToVw(20px);
    border: convertToVw(3px) solid $border-calendar;
    background-color: #cbeaff;
  }

  &-square.white {
    background-color: $white-color;
  }
}

//custom modal calendar
.item-selected {
  width: 100%;
  margin-top: convertToVw(22px);

  & .item-date {
    @include font(convertToVw(20px), 500, convertToVw(35px));
  }
  .item-date-empty {
    display: block;
    min-height: convertToVw(100px);

    @include mq_min_750 {
      min-height: 100px;
    }
  }

  .btn-date.btn-date-cast {
    padding: convertToVw(23px) 0 convertToVw(27px) 0;
    background-color: #0087ff;
  }

  .btn-date.btn-date-cast.hidden {
    visibility: hidden;
  }

  .btn-date {
    width: 100%;
    font-family: 'Hiragino Sans', sans-serif;
    border: none;
    background-color: #008aff;
    color: $white-color;
    margin-bottom: convertToVw(22px);
    @include font(convertToVw(29px), 500, convertToVw(35px));
    padding: convertToVw(23px);
  }

  .item-hour-line {
    .note-calendar {
      @include font(convertToVw(24px), 300, convertToVw(46px));
      margin-bottom: convertToVw(25px);
      white-space: nowrap;
    }

    .list-hour {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
      grid-row-gap: convertToVw(15px);
    }

    .list-hour.booking-soji {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: convertToVw(15px);

      button {
        border-radius: convertToVw(8px);
        justify-content: center;
        display: flex;
        align-items: center;
        border: none;
        color: $black-color-v2;
        max-width: convertToVw(220px);
        height: convertToVw(80px);
        @include font(convertToVw(23px), 400, convertToVw(90px));

        @include mq_min_1024 {
          max-width: convertToVw(220px);
          height: convertToVw(80px);
          @include font(convertToVw(29px), 400, convertToVw(90px));
        }
      }
    }
    .list-hour.customer-hearing-list-hour {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: convertToVw(15px);
      text-align: start;
      margin-top: convertToVw(10px);

      .btn-hour {
        background-color: $white-color;
        color: #1a1a1a;
      }
    }

    & button {
      border-radius: convertToVw(8px);
      padding: 0 convertToVw(16px);
      border: none;
      height: convertToVw(110px);
      @include font(convertToVw(29px), normal, convertToVw(90px));
    }

    & .btn-hour {
      font-family: 'Hiragino Sans', sans-serif;
    }

    & .disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    & .btn-hour-active {
      background-color: #0c8aff !important;
      cursor: pointer;
      color: white !important;
    }
  }

  & .check-hour {
    @include flexbox(flex, space-between, center, wrap);
    padding-top: convertToVw(55px);

    &:nth-child(3) {
      margin-bottom: convertToVw(20px);
    }
    .checkbox-warning {
      color: #e30012 !important;
      @include font(convertToVw(18px), 500, convertToVw(25px));
    }

    & span {
      @include font(convertToVw(25px), 500, convertToVw(44px));

      &:nth-child(1) {
        @include font(convertToVw(20px), 500, convertToVw(37px));
      }
    }

    & .checkbox-confirm {
      @include flexbox(flex, null, center, wrap);

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
      }

      input {
        width: convertToVw(43px);
        height: convertToVw(42px);
        margin-left: convertToVw(26px);
      }
    }
  }
  & .calendar-modal-bottom {
    .checkbox-warning {
      color: $danger-color !important;
      @include font(convertToVw(18px), 400, convertToVw(45px));
    }

    .calendar-frequency {
      @include font(convertToVw(20px), 400, convertToVw(31px));
      color: #e95514;
      margin-top: convertToVw(30px);
    }

    & #calendar-frequency-item {
      border-bottom: none;
      @include font(convertToVw(20px), 500, convertToVw(35px));
      padding: 0 !important;
      color: #1a1a1a;
    }
  }
}

.empty-item {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid $button-color-first;
  padding: convertToVw(43px) convertToVw(0px) convertToVw(37px) convertToVw(0px);
  background-color: $white-color;

  .data-empty {
    @include font(convertToVw(20px), 500, convertToVw(35px));
    text-align: center;
    color: $font-color-new;
  }
  @include mq_min_750 {
    padding: 43px 0px 37px 0px;

    .data-empty {
      @include font(20px, null, 35px);
    }
  }
}
.ant-select-dropdow-custom {
  width: 100% !important;
  max-width: convertToVw(630px);
}

.form-group .ant-input-number {
  width: 100%;

  .ant-input-number-input {
    padding: 0 !important;
  }
}

.ant-input-number-status-error.ant-input-number {
  border: convertToVw(3px) solid $danger-color-v2;
}

.ant-select-selector {
  .ant-select-selection-search {
    .ant-select-selection-search-input {
      font-size: convertToVw(24px);
    }
  }
}

// delete arrow up down in input type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-select-status-error,
.ant-input-group-wrapper-status-error {
  border: 2px solid $color-border-input !important;
}
.ant-input-focused {
  border-inline-end-width: convertToVw(3px) !important;
}
.ant-btn:hover {
  opacity: 0.7;
}

.ant-input-clear-icon {
  svg {
    width: convertToVw(20px);
    height: convertToVw(20px);
  }
}

.calendar-modal-bottom {
  margin-top: convertToVw(30px);

  @include mq_min_750 {
    margin-top: 30px;
  }
}

.text-calendar-hearing {
  letter-spacing: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:where(.css-dev-only-do-not-override-1poc1a7).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1poc1a7).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $transparent-color !important;
}
.ant-form input[type='checkbox']:focus {
  box-shadow: none;
}

@include mq_min_750 {
  .ant-checkbox + span {
    @include font(24px, 300, 42px);
  }

  .ant-radio-wrapper .ant-radio-inner {
    width: 55px;
    height: 55px;
  }

  .ant-form label {
    @include font(24px, 400, 42px);
  }

  //custom validate
  .error-validate {
    border-radius: 9px;
    margin: 35px 0 30px 0;
    height: 80px;
    @include font(20px, 400, 35px);
  }

  //custom-modal
  .ant-modal {
    width: 680px !important;
    top: 52px;

    .modal-date {
      height: 900px;
      width: 680px;
    }
    .ant-modal-content {
      border-radius: 30px;

      & .ant-modal-close {
        width: 60px;
        height: 60px;
        margin-top: 5px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
      & .ant-modal-close-x {
        font-size: 30px;

        img {
          width: 44px;
          height: 36px;
        }

        svg {
          width: 60px;
          height: 60px;
          margin-right: 30px;
          padding-top: 10px;
        }
      }

      & .ant-modal-header {
        border-radius: 30px 30px 0 0;

        & .ant-modal-title {
          padding: 41px 40px 34px 41px;

          @include font(30px, 400, 52.5px);

          .head-modal-title {
            @include font(30px, 400, 36px);
          }
        }
      }

      & .ant-modal-body {
        max-height: 75vh;

        & p {
          @include font(24px, 300, 30px);
          padding: 32px 32px 28px 41px !important;
        }

        &::-webkit-scrollbar {
          width: 10px;
          border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
        }
      }
    }
  }

  .ant-modal.modal-delete-cast {
    width: 600px !important;
    .ant-modal-content {
      border-radius: 0;
    }
    img {
      width: 70px;
    }

    .modal-delete-wrap {
      padding: 24px 24px;
    }

    .text-delete {
      font-size: 24px;
      display: inline-block;
    }

    .delete-actions {
      display: flex;
      gap: 20px;
      margin-top: 26px;
    }

    button {
      min-width: 100px;
      height: 40px;
      cursor: pointer;
      font-size: 20px;
      transition: all 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn-cancel {
      border: 1px solid #ccc;
      background-color: $transparent-color;
    }

    .btn-delete {
      color: $white-color;
      border: none;
    }
  }

  .close-modal {
    width: 40px;
    height: 40px;

    svg {
      width: 37px;
      height: 35px;
    }
  }

  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper {
    padding: convertToVw(16px);

    @include mq_min_750 {
      padding: 16px;
    }
  }

  .modal-container-emotion {
    .icon-modal {
      margin-bottom: 28px;
    }

    .btn-bottom-emotion {
      margin-top: 30px;

      .ct-allow {
        margin-bottom: 15px;
      }
    }
  }
  .ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
    .ant-checkbox-inner {
      border: 3px solid $danger-color-v2 !important;
    }
  }

  .checkbox:checked + .checkboxLabel::before {
    border-radius: 2px;
    content: '\2713';
    font-size: 19px;
    line-height: 18px;
  }

  .calendar .selected {
    background-color: $calendar-color-matching !important;
    color: $white-color;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    margin-bottom: 35px;
  }

  .annotate {
    gap: 26px;
    margin-top: 20px;
    @include font(20px, 500, 35px);

    &-item {
      span {
        margin-left: 7px;
      }
    }

    &-square {
      width: 20px;
      height: 20px;
      border: 3px solid $border-calendar;
    }
  }

  //custom modal calendar
  .item-selected {
    margin-top: 22px;

    & .item-date {
      @include font(20px, null, 35px);
    }

    .btn-date.btn-date-cast {
      padding: 23px 0 27px 0;
      @include font(29px, 500, 35px);
    }

    .btn-date {
      margin-bottom: 22px;
      @include font(29px, 500, 35px);
      padding: 23px;
    }
    .item-hour-line {
      .note-calendar {
        margin-bottom: 25px;
        white-space: nowrap;
        @include font(26px, 400, 46px);
      }
      .list-hour {
        grid-row-gap: 15px;
      }

      .list-hour.booking-soji {
        grid-gap: 15px;

        button {
          border-radius: 8px;
          max-width: 220px;
          height: 80px;
          @include font(29px, 400, 90px);
        }
      }
      .list-hour.customer-hearing-list-hour {
        grid-gap: 15px;
      }
      margin-top: 10px;

      & button {
        border-radius: 8px;
        padding: 0 16px;
        border: none;
        height: 110px;
        @include font(29px, normal, 90px);
      }
    }

    & .check-hour {
      padding-top: 55px;

      &:nth-child(3) {
        margin-bottom: 20px;
      }

      .checkbox-warning {
        @include font(18px, 400, 25px);
      }

      & span {
        @include font(25px, null, 44px);

        &:nth-child(1) {
          @include font(20px, null, 37px);
        }
      }
      & .checkbox-confirm {
        input {
          width: 43px;
          height: 42px;
          margin-left: 26px;
        }
      }
    }

    & .calendar-modal-bottom {
      .checkbox-warning {
        @include font(18px, 400, 45px);
      }

      .calendar-frequency {
        @include font(20px, 400, 31px);
        margin-top: 30px;
      }

      & #calendar-frequency-item {
        @include font(20px, null, 35px);
      }
    }
  }
  .ant-select-dropdow-custom {
    max-width: 630px;
  }
  .ant-input-number-status-error.ant-input-number {
    border: 3px solid $danger-color-v2;
  }

  .ant-select-selector {
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: 24px;
      }
    }
  }

  // delete arrow up down in input type number
  .ant-input-focused {
    border-inline-end-width: 3px !important;
  }

  .ant-input-clear-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .icon-loading {
    color: $border-bottom-default;
    font-size: convertToVw(45px);

    @include mq_min_750 {
      font-size: 45px;
    }
  }
}

.loading-detail {
  color: $border-bottom-default;
}

.ant-modal.ant-modal-confirm {
  width: convertToVw(600px) !important;
  height: auto;
  @include mq_min_750 {
    width: 600px !important;
  }

  .ant-modal-content {
    border-radius: 0;
    box-shadow: 2px 2px 4px #888;
    border: 1px solid #aaa;

    .ant-modal-close {
      display: flex;
      justify-content: flex-start;
      width: convertToVw(20px);
      height: convertToVw(20px);
      margin-top: 0;
      top: convertToVw(17px);
      inset-inline-end: convertToVw(20px);

      @include mq_min_750 {
        width: 20px;
        height: 20px;
        top: 17px;
        inset-inline-end: 18px;
      }

      &:hover {
        background-color: transparent;
      }

      .ant-modal-close-x {
        font-size: convertToVw(25px);
        display: flex;
        justify-content: flex-start;

        @include mq_min_750 {
          font-size: 25px;
        }

        .icon-close-confirm {
          display: flex;
        }

        svg {
          width: convertToVw(26px);
          height: convertToVw(20px);

          @include mq_min_750 {
            width: 26px;
            height: 20px;
          }
          margin-right: 0;
          padding-top: 0;
        }
      }
    }
    .ant-modal-body {
      max-height: 100%;

      .ant-modal-confirm-title {
        display: flex;
        justify-content: space-between;
        @include font(convertToVw(21px), 500, convertToVw(30px));

        @include mq_min_750 {
          @include font(21px, 500, 30px);
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    gap: convertToVw(12px);
    margin-top: convertToVw(20px);

    @include mq_min_750 {
      gap: 12px;
      margin-top: 20px;
    }

    button {
      min-width: convertToVw(100px);
      height: convertToVw(40px);
      @include font(convertToVw(18px), 500, convertToVw(20px));
      border-radius: 0;
      box-shadow: 0 0 0px #f00;
      border: 1px solid #d9d9d9;

      @include mq_min_750 {
        min-width: 100px;
        height: 40px;
        @include font(18px, 500, 20px);
      }

      &:nth-child(1) {
        order: 2;
        color: #1a1a1a;
        background-color: #f9f9f9;
      }

      &:nth-child(2) {
        background-color: #5b99f9;

        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-1poc1a7).ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #e50012 !important;
}
