.block-procedure {
  .ant-checkbox-wrapper {
    align-items: center;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-radius: convertToVw(9px);

    @include mq_min_750 {
      border-radius: 9px;
    }
  }

  :where(.css-dev-only-do-not-override-1poc1a7).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border-color: $transparent-color !important;
    background-color: $bg-color-input !important;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: $transparent-color !important;
    background-color: $bg-color-input !important;
  }
  .warning {
    @include font(convertToVw(26px), 300, convertToVw(51px));
    color: #e60012;

    @include mq_min_750 {
      @include font(26px, 300, 51px);
    }
  }

  .mail-support-cast {
    margin-bottom: convertToVw(85px);

    @include mq_min_750 {
      margin-bottom: 85px;
    }
  }

  .rule-delete-note {
    margin-top: 30px;
  }

  .warning.warning-user-block.note {
    @include font(convertToVw(23px), 300, convertToVw(26px));

    @include mq_min_750 {
      @include font(23px, 300, 26px);
    }
  }

  .text-warning-item {
    margin-top: convertToVw(66px);

    @include mq_min_750 {
      margin-top: 66px;
    }
  }

  .warning-delete-account.hidden {
    display: none;
  }

  .item-des-procedure {
    @include textAlign(center);

    .title-delete-item {
      @include font(convertToVw(30px), 600, convertToVw(53.5px));

      @include mq_min_750 {
        @include font(30px, null, 53.5px);
      }
    }
    .rule-mail {
      color: #036eb8;
      text-decoration: underline;
      @include font(convertToVw(26px), 300, convertToVw(31px));

      @include mq_min_750 {
        @include font(26px, 300, 31px);
      }
    }

    .warning-cast-item {
      margin-top: convertToVw(66px);
      margin-bottom: convertToVw(50px);

      @include mq_min_750 {
        margin-top: 66px;
        margin-bottom: 50px;
      }
    }

    .warning-delete {
      @include font(convertToVw(22px), 300, convertToVw(26px));
      margin-top: convertToVw(45px);
      color: #e60012;

      @include mq_min_750 {
        @include font(22px, 300, 26px);
        margin-top: 45px;
      }
    }

    .warning.warning-cast-block.note {
      @include font(convertToVw(22px), 300, 1.4);

      @include mq_min_750 {
        @include font(22px, 300, 1.4);
      }
    }

    .des-procedure {
      margin-top: convertToVw(40px);

      @include mq_min_750 {
        margin-top: 40px;
      }
    }

    & .check-term {
      margin-bottom: convertToVw(110px);
      @include flexbox(flex, center, center, null);
      @include font(convertToVw(24px), 300, convertToVw(46px));

      @include mq_min_750 {
        margin-bottom: 110px;
        @include font(24px, 300, 46px);
      }
    }

    & .check-term.cndlt {
      margin-bottom: convertToVw(228px);
      @include flexbox(flex, center, center, null);
      @include font(convertToVw(24px), 300, convertToVw(46px));

      @include mq_min_750 {
        margin-bottom: 228px;
        @include font(24px, 300, 46px);
      }
    }

    & .check-term.ccndlt {
      margin-bottom: convertToVw(188px);
      @include flexbox(flex, center, center, null);
      @include font(convertToVw(24px), 300, convertToVw(46px));

      @include mq_min_750 {
        margin-bottom: 188px;
        @include font(24px, 300, 46px);
      }
    }

    & .check-term.my-0 {
      margin-bottom: convertToVw(46px);
      @include flexbox(flex, center, center, null);
      @include font(convertToVw(24px), 300, convertToVw(46px));

      @include mq_min_750 {
        margin-bottom: 46px;
        @include font(24px, 300, 46px);
      }
    }
  }

  .text-delete-account {
    @include font(convertToVw(26px), 400, convertToVw(46px));
    margin-top: convertToVw(66px);
    white-space: nowrap;

    @include mq_min_750 {
      @include font(26px, 400, 46px);
      margin-top: 66px;
    }

    .rule-user-note-item {
      margin-top: convertToVw(71px);

      @include mq_min_750 {
        margin-top: 71px;
      }
    }

    .rule-user-note-item-second {
      margin-bottom: convertToVw(111px);
      margin-top: convertToVw(52px);

      @include mq_min_750 {
        margin-top: 52px;
        margin-bottom: 111px;
      }
    }
  }
  .check-term {
    @include flexbox(flex, center, center, null);
    margin-top: convertToVw(28px);
    @include font(convertToVw(24px), 300, convertToVw(46px));

    @include mq_min_750 {
      column-gap: 10px;
      margin-top: 28px;
      @include font(24px, 300, 46px);
    }

    .ant-checkbox-wrapper {
      column-gap: convertToVw(10px);
    }

    & span {
      @include font(convertToVw(24px), 400, convertToVw(46px));

      @include mq_min_750 {
        @include font(24px, 400, 46px);
      }
    }

    & .checkbox-button {
      background-color: $bg-color-input;
      border: none;
      border-radius: convertToVw(9px);
      width: convertToVw(57px);
      height: convertToVw(56px);

      @include mq_min_750 {
        border-radius: 9px;
        width: 57px;
        height: 56px;
      }
    }
  }
}

.block-btn-produce {
  margin-top: convertToVw(40px);
  display: flex;
  width: 100%;
  column-gap: convertToVw(15px);

  @include mq_min_750 {
    margin-top: 40px;
    column-gap: 15px;
  }

  .btn {
    color: $white-color;
  }

  .btn.isCustomerDelete {
    margin: 0 auto;
  }
}
