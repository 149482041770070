.cast-instructions {
  .instructions-content {
    .text-ins-bold {
      font-size: convertToVw(28px);
      font-weight: 600;
      line-height: convertToVw(33px);
    }

    h4.text-ins-bold {
      margin-top: convertToVw(77px);
    }

    .second-content {
      margin-top: convertToVw(51px);

      span {
        font-size: convertToVw(28px);
        font-weight: 300;
        line-height: convertToVw(33px);
        display: inline-block;
        margin-top: convertToVw(18px);
        letter-spacing: -3px;
      }
    }

    h5 {
      font-size: convertToVw(24px);
      font-weight: 600;
      line-height: convertToVw(28px);
      margin-top: convertToVw(50px);
    }

    .third-content {
      margin-top: convertToVw(24px);
    }

    span.text-ins-bold {
      display: inline-block;
      margin-top: convertToVw(24px);
    }

    span.text-ins-bold.mt-3 {
      margin-top: convertToVw(12px);
    }

    .instructions-action {
      margin-top: convertToVw(58px);

      .ins-btn-allow {
        max-width: convertToVw(478px);
      }

      .ins-btn-back {
        margin-top: convertToVw(73px);
      }
    }
  }
}

@include mq_min_750 {
  .cast-instructions {
    .instructions-content {
      .text-ins-bold {
        font-size: 28px;
        line-height: 33px;
      }

      h4.text-ins-bold {
        margin-top: 77px;
      }

      .second-content {
        margin-top: 51px;

        span {
          font-size: 28px;
          line-height: 33px;
          margin-top: 18px;
        }
      }

      h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        margin-top: 50px;
      }

      span.text-ins-bold {
        margin-top: 24px;
      }

      span.text-ins-bold.mt-3 {
        margin-top: 12px;
      }

      .instructions-action {
        margin-top: 58px;

        .ins-btn-allow {
          max-width: 478px;
        }

        .ins-btn-back {
          margin-top: 73px;
        }
      }
    }
  }
}
