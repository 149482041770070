.admin-wrapper {
  .container {
    max-width: 1480px;
    width: 90%;
    margin: 0 auto;
  }
  
  background-color: $bg-color-admin;
  overflow: auto;
  height: 100vh;
}
