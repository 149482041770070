.drawer-wrapper {
  position: relative;
  border: none;
  outline: none;
  box-shadow: none;

  .content-bot {
    .account-setting-element:first-child {
      border-top: 1px solid $border-bottom-default;
      padding-top: convertToVw(32px) !important;
    }

    .account-setting-element {
      border-bottom-width: 1px !important;
    }

    @include mq_min_750 {
      .account-setting-element:first-child {
        padding-top: 32px !important;
      }
    }
  }
}

.ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer {
  position: absolute;
  border: none;
  outline: none;
  box-shadow: none;
  overflow-y: auto;
  min-height: 100vh;

  .ant-drawer-content-wrapper {
    .ant-drawer-wrapper-body {
      .customer-dashboard-block {
        padding: 0;
      }

      .ant-drawer-header {
        display: none;

        .ant-drawer-header-title {
          display: none;
        }

        .ant-drawer-extra {
          width: 100%;
          flex: 1;
          @include flexbox(flex, space-between, center, nowrap);
        }
      }
    }
  }

  .btn-account-setting {
    margin-bottom: 0 !important;
  }
}

.ant-drawer .ant-drawer-mask {
  background-color: #fff;
}
.user .ant-drawer-content-wrapper {
  height: 900px;

  @include mq_min_540 {
    height: 1250px;
  }
}
.cast .ant-drawer-content-wrapper {
  height: -webkit-fill-available;
  min-height: 900px;
  background-color: #f8f4ed;

  @include mq_min_675 {
    height: 966px;
  }
}
