.cast-modal {
  &-content {
    padding: convertToVw(34px);
    height: convertToVw(800px);

    .text-group {
      .text-title {
        text-align: center;
        margin-top: calc(convertToVw(200px) - convertToVw(34px));

        span {
          font-size: convertToVw(30px);
          font-weight: 600;
        }
      }

      .text-desc {
        text-align: center;
        margin-top: convertToVw(41px);

        span {
          font-size: convertToVw(26px);
          font-weight: 300;
        }
      }
    }

    .back-btn-cast {
      margin-top: convertToVw(156px);
      height: fit-content;
    }
  }

  &-content.success {
    padding: convertToVw(34px);
    height: convertToVw(410px);

    .text-group {
      .text-title {
        margin-top: calc(convertToVw(157px) - convertToVw(34px));
      }
    }

    .back-btn-cast {
      margin-top: convertToVw(104px);
      height: fit-content;
    }
  }
}

@include mq_min_750 {
  .cast-modal {
    &-content {
      padding: 34px;
      height: 800px;

      .text-group {
        .text-title {
          text-align: center;
          margin-top: calc(233px - 34px);

          span {
            font-size: 30px;
            line-height: 36px;
          }
        }

        .text-desc {
          text-align: center;
          margin-top: 41px;

          span {
            font-size: 26px;
            line-height: 31px;
          }
        }
      }

      .back-btn-cast {
        margin-top: 176px;
        height: fit-content;
      }
    }

    &-content.success {
      padding: 34px;
      height: 410px;

      .text-group {
        .text-title {
          margin-top: calc(157px - 34px);
        }
      }

      .back-btn-cast {
        margin-top: 104px;
        height: fit-content;
      }
    }
  }
}
